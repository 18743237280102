<template>
  <div class="shopifyTasksContainer">
    <TeeInBlueBearerDialog />
    <div v-if="!shopifyTasksLoading">
      <div
        :class="
          navigationExpanded
            ? 'functions-header functions-header--expanded'
            : 'functions-header'
        "
      >
        <v-row dense>
          <v-col cols="12" md="2">
            <v-autocomplete
              :items="allDrawers"
              dense
              item-text="label"
              item-value="uid"
              outlined
              label="Default Assignee"
              hide-details
              :value="selectedAssignee"
              @input="setSelectedAssignee"
              return-object
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="2">
            <v-select
              :items="editStyles"
              outlined
              item-text="text"
              item-value="value"
              label="Edit Type"
              return-object
              v-model="selectedEditStyle"
              dense
              hide-details
            ></v-select>
          </v-col>
          <v-col cols="12" md="4">
            <div class="d-flex justify-center">
              <v-chip
                @click="setSearchFilter('unmarked')"
                class="ma-2 overflow-visible"
                color="primary"
                :outlined="shopifyTaskFilter !== 'unmarked'"
              >
                <v-badge :content="unmarkedTaskIdsLength">
                  Unmarked Tasks
                </v-badge>
              </v-chip>
              <v-chip
                @click="setSearchFilter('marked')"
                class="ma-2 overflow-visible"
                color="primary"
                :outlined="shopifyTaskFilter !== 'marked'"
              >
                <v-badge :content="markedTaskIdsLength">
                  <v-avatar left>
                    <v-icon>mdi-alert-decagram</v-icon>
                  </v-avatar>
                  Marked Tasks
                </v-badge>
              </v-chip>
              <div class="text-center">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      class="ma-2 overflow-visible"
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      outlined
                    >
                      <v-icon>
                        mdi-dots-vertical
                      </v-icon>
                    </v-chip>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in moreOptions"
                      :key="index"
                      @click="item.onClick"
                    >
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="searchTerm"
              append-icon="mdi-magnify"
              label="Order Number, Name or Email"
              type="text"
              hide-details
              dense
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
      <v-expansion-panels class="shop-tabs" :multiple="true">
        <v-expansion-panel>
          <v-expansion-panel-header class="e-panel-header">
            <div class="e-panel-header__count">{{ shopifyTasksOH.length }}</div>
            <div class="e-panel-header__name"> OhMyArt (US, CA) </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <CardsGrid
                v-if="shopifyTasksOH.length"
                :cols="gridCols"
                :tasks="shopifyTasksOH"
                :selected-assignee="selectedAssignee"
                :selected-edit-style="selectedEditStyle"
                :CardComponent="cardComponent"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header class="e-panel-header">
            <div class="e-panel-header__count">{{ shopifyTasksAQ.length }}</div>
            <div class="e-panel-header__name"> Artquarell (DE,FR,ES) </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <CardsGrid
              v-if="shopifyTasksAQ.length"
              :cols="gridCols"
              :tasks="shopifyTasksAQ"
              :selected-assignee="selectedAssignee"
              :selected-edit-style="selectedEditStyle"
              :CardComponent="cardComponent"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header class="e-panel-header">
            <div class="e-panel-header__count">{{ shopifyTasksTM.length }}</div>
            <div class="e-panel-header__name"> TierBeiMir (DE) </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <CardsGrid
              v-if="shopifyTasksTM.length"
              :cols="gridCols"
              :tasks="shopifyTasksTM"
              :CardComponent="cardComponent"
              :selected-assignee="selectedAssignee"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <StateDisplay v-else :loading="shopifyTasksLoading" />
  </div>
</template>
<script>
import ImportTaskCard from "@/components/TaskCards/ImportTaskCard";
import { mapGetters, mapMutations } from "vuex";
import {editStyles, permissions} from "@/constants";
import TeeInBlueBearerDialog from "@/components/TeeInBlueBearerDialog";
import CardsGrid from "@/components/CardsGrid";
import StateDisplay from "@/components/StateDisplay";
import {EventBus, events} from "../../event-bus";
import {delay} from "@/utils";

export default {
  components: { StateDisplay, CardsGrid, TeeInBlueBearerDialog },
  data() {
    return {
      searchTerm: "",
      stopRemovePreview: false,
      gridCols: {
        cols: "12",
        sm: "6",
        md: "6",
        lg: "4",
      },
      selectedEditStyle: editStyles[0],
      editStyles: editStyles,
      cardComponent: ImportTaskCard,
      moreOptions: [
        {
          title: "Ultra Upscale All",
          onClick: this.upscaleUltraALl
        },
        {
          title: "Remove All templates",
          onClick: this.removeAllPreviews
        },
      ]
    };
  },
  computed: {
    ...mapGetters([
      "unmarkedTaskIdsLength",
      "shopifyTasksAQ",
      "shopifyTasksTM",
      "shopifyTasksOH",
      "shopifyTasksLoading",
      "shopifyTaskFilter",
      "allUsers",
      "selectedAssignee",
      "navigationExpanded",
      "markedTaskIdsLength",
      "selectedShopId",
      "drawTasksPerUser",
      "userNameIdMap",
    ]),
    selectedTab: {
      get() {
        return this.selectedShopId;
      },
      set(value) {
        this.setSelectedShopId(value);
      },
    },
    allDrawers() {
      const allUsersAllowedToDraw = this.allUsers
        .filter((user) => user.permissions.includes(permissions.TASK_DRAWING))
        .map((user) => {
          return {
            ...user,
            label: `${user.username} (${this.drawTasksPerUser[user.uid] || 0})`,
          };
        });
      return [
        {
          label: "-",
          uid: "",
        },
        ...allUsersAllowedToDraw,
      ];
    },
  },
  watch: {
    searchTerm() {
      this.setSearchTerm(this.searchTerm);
    },
  },
  mounted() {
    // Add event listener when the component is mounted
    document.addEventListener('keypress', this.changeEditType);
    EventBus.$on(events.STOP_REMOVE_TEMPLATES, this.stopRemoveAllPreviews)
  },
  beforeDestroy() {
    // Remove event listener to avoid memory leaks
    document.removeEventListener('keypress', this.changeEditType);
  },
  methods: {
    ...mapMutations([
      "setSearchTerm",
      "setSearchFilter",
      "setSelectedAssignee",
      "setSelectedShopId",
    ]),
    changeEditType(event) {
      const style = editStyles.find(item => item.value === event.key)
      // Check which key was pressed and update the value accordingly
      if (style) {
        this.selectedEditStyle = style
      }
    },
    async upscaleUltraALl() {
      console.log('START UPSCALE ALL')
      for await (const task of this.shopifyTasksAQ) {
        if (task.img && !task.previewExists && !task.removed) {
          EventBus.$emit(events.ULTRA_UPSCALE_BY_ID + task.uid)
          await delay(Math.floor(Math.random() * (5000 - 1000 + 1)) + 1000)
        }
      }
    },
    async stopRemoveAllPreviews() {
      console.log('STOP GENERATING TEMPLATES')
      this.stopRemovePreview = true
      await delay(3000)
      this.stopRemovePreview = false
    },
    async removeAllPreviews() {
      for await (const task of this.shopifyTasksAQ) {
        if (this.stopRemovePreview) {
          break;
        }
        if (!task.removed) {
          EventBus.$emit(events.REMOVE_TEMPLATE_PREVIEW_BY_ID + task.uid)
          await delay(Math.floor(Math.random() * (1500 - 500 + 1)) + 1000)
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.shopifyTasksContainer {
  min-height: 500px;
}
.functions-header {
  position: fixed;
  z-index: 4;
  background-color: white;
  width: calc(100% - 80px);
  padding-top: 20px;
  padding-left: 20px;
  padding-bottom: 10px;
  top: 60px;
  left: 60px;
  transition: margin-left ease 200ms;

  &--expanded {
    margin-left: 200px;
    transition: margin-left ease 200ms;
  }
}

.shop-tabs {
  padding-top: 78px;

  @media (max-width: 959px) {
    padding-top: 190px;
  }
}

.e-panel-header {
  display: flex;
  align-items: start;
  justify-content: start;

  &__count {
    font-weight: bold !important;
  }

  &__name {
    font-weight: lighter !important;
    margin-left: -30px;
  }
}

.task-view {
  margin-top: 12px;
}

.download-type-display {
  background: black;
  color: white;
}
</style>

<template>
  <v-card>
    <v-card-title class="flex justify-space-between">
      Print Variants
      <v-btn @click="openAddNewPrintVariantDialog" color="success"
        >Add Print Variant
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :loading="loadingPrintVariants"
        :headers="headers"
        :sort-by="['name']"
        :items="printVariants"
        :items-per-page="50"
        class="elevation-1"
      >
        <template v-slot:item.name="{ item }">
          {{ item.name }}
        </template>
        <template v-slot:item.availableCountries="{ item }">
          {{ Object.keys(item.fulfillRank).join(", ") }}
        </template>
        <template v-slot:item.availableFulfiller="{ item }">
          {{ Object.keys(item.fulfiller).join(", ") }}
        </template>
        <template v-slot:item.defaultFulfiller="{ item }">
          <span
            :key="countryCode"
            v-for="countryCode in Object.keys(item.fulfillRank)"
          >
            {{ countryCode }}: {{ item.fulfillRank[countryCode][0] }}
          </span>
        </template>
        <template v-slot:item.costs="{ item }">
          <span
            :key="fulfillerId"
            v-for="fulfillerId in Object.keys(item.fulfiller)"
          >
            {{ fulfillerId }}: {{ item.fulfiller[fulfillerId].costs }}€ / {{ item.fulfiller[fulfillerId].shippingCosts }}€ <br/>
          </span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small @click="() => editPrintVariant(item)"
            >mdi-pencil
          </v-icon>
        </template>
      </v-data-table>
    </v-card-text>
    <PrintVariantDialog
      :show="!!showPrintVariantDialog"
      :on-close="closePrintVariantDialog"
      :print-variant="printVariantToEdit"
    />
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PrintVariantDialog from "@/components/Settings/Products/PrintVariants/PrintVariantDialog";

export default {
  name: "PrintVariants",
  components: { PrintVariantDialog },
  data() {
    return {
      showPrintVariantDialog: null,
      printVariantToEdit: null,
      headers: [
        { text: "Print Variant Name", value: "name" },
        { text: "Available Countries", value: "availableCountries" },
        { text: "Available Fulfiller", value: "availableFulfiller" },
        { text: "Default Fulfiller", value: "defaultFulfiller" },
        { text: "Costs", value: "costs" },
        { text: "", value: "actions" },
      ],
    };
  },
  computed: {
    ...mapGetters(["printVariants", "loadingPrintVariants"]),
  },
  mounted() {
    this.loadPrintVariants();
  },
  methods: {
    ...mapActions(["loadPrintVariants"]),
    editPrintVariant(printVariant) {
      this.printVariantToEdit = printVariant;
      this.showPrintVariantDialog = true;
    },
    openAddNewPrintVariantDialog() {
      this.printVariantToEdit = null;
      this.showPrintVariantDialog = true;
    },
    closePrintVariantDialog() {
      this.printVariantToEdit = null;
      this.showPrintVariantDialog = false;
    },
  },
};
</script>

<style scoped></style>

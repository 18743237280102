<template>
  <div>
    <div v-if="!loading && order" class="orderView">
      <div class="orderViewHeader">
        <div class="d-flex flex-column">
          <h1 class="orderViewHeader__orderName">{{ order.orderName }}</h1>
          <h3
            class="text--lighten-1 font-weight-light pa-1 pt-3"
            style="line-height: 16px"
          >
            {{ getDateFromNow(order.orderDate) }} ({{
              getDate(order.orderDate)
            }})
          </h3>
        </div>
        <h2 class="orderViewHeader__orderStatus">{{ order.status }}</h2>
        <v-spacer />
        <v-btn @click="openOrderInShopify" large rounded dark>
          Open in Shopify
          <v-icon class="ml-3">mdi-open-in-new</v-icon>
        </v-btn>
      </div>
      <v-row class="mt-6">
        <v-col cols="12" md="8">
          <div v-if="order.fulfillment">
            <v-card
              class="mb-6"
              v-for="fulfillerId in Object.keys(order.fulfillment)"
              :key="fulfillerId"
              cols="12"
              md="8"
            >
              <v-card-title>{{ fulfillerId }} Fulfillment</v-card-title>
              <v-card-text>
                <div
                  class="fulfillment"
                  :key="fulfillment.fulfiller_id"
                  v-for="fulfillment in order.fulfillment[fulfillerId]"
                >
                  <h1
                    class="fulfillment__fulfillmentId"
                    :class="
                      fulfillment.success || fulfillment.pending
                        ? ''
                        : 'text-danger'
                    "
                  >
                    {{ fulfillment.fulfiller_id }}
                    {{
                      fulfillment.pending
                        ? "(PENDING)"
                        : !fulfillment.success
                        ? "FAILED"
                        : ""
                    }}
                  </h1>
                  <h2 class="fulfillment__creationDate">
                    {{ getDateFromNow(fulfillment.creationDate) }} ({{
                      getDate(fulfillment.creationDate)
                    }})
                  </h2>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Variant</th>
                          <th class="text-left">Quantity</th>
                          <th class="text-left">File URL</th>
                          <th class="text-left">Produced</th>
                          <th class="text-left">Shipped</th>
                          <th class="text-left">Tracking ID</th>
                          <th class="text-left">Carrier</th>
                          <th class="text-left">Shipped ago</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in fulfillment.fulfillItems"
                          :key="item.name"
                        >
                          <td>{{ item.variant }} {{item.express ? '(EXPRESS)' : ''}}</td>
                          <td>{{ item.quantity }}</td>
                          <td>
                            <a :href="item.file" target="_blank">{{
                              `${item.file?.substring(0, 10)}...`
                            }}</a>
                          </td>
                          <td>{{ !!item.produced ? "Yes" : "No" }}</td>
                          <td>{{ !!item.shipment ? "Yes" : "No" }}</td>
                          <td>
                            <a
                              :href="item.shipment?.trackingUrl"
                              target="_blank"
                              >{{ item.shipment?.trackingCode }}</a
                            >
                          </td>
                          <td>{{ item.shipment?.carrier }}</td>
                          <td>
                            {{
                              item.shipment?.shippingDate
                                ? getDateFromNow(item.shipment?.shippingDate)
                                : ""
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
              </v-card-text>
            </v-card>
          </div>

          <v-card>
            <v-card-title>{{ order.tasks.length }} Tasks</v-card-title>
            <v-card-text>
              <OrderTasks
                :key="order.localUpdateKey"
                :order-tasks="order.tasks"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card>
            <v-card-title> Order Actions</v-card-title>
            <v-card-text>
              <v-btn
                class="mr-6 mt-6"
                v-if="showFulfillOptions"
                @click="openManualFulfillmentDialog"
                dark
                >Manual Fulfill
              </v-btn>
              <v-btn
                class="mt-6"
                @click="() => (showCancelOrderDialog = true)"
                color="error"
                >Cancel Order
              </v-btn>
              <v-btn
                class="mr-6 mt-6"
                v-if="showFulfillOptions"
                @click="openReproduceDialog"
                dark
                >Reproduce
              </v-btn>
            </v-card-text>
          </v-card>
          <v-card class="orderViewCustomerCard">
            <v-card-title>Customer</v-card-title>
            <v-card-text>
              <div class="orderViewCustomerCard__name">
                {{ order.customer.firstName }}
              </div>
              <div class="orderViewCustomerCard__email">
                <div v-if="!isEditEmail" >
                  {{ order.customer.email }}
                  <v-icon @click="copyEmailToClipboard"
                  >mdi-clipboard
                  </v-icon>
                  <v-btn @click="() => editEmail(true)" icon>
                    <v-icon
                    >mdi-pencil-circle
                    </v-icon>
                  </v-btn>
                </div>
                <div class="d-flex mt-3 align-content-center" v-else >
                  <v-text-field outlined label="Customer E-Mail" v-model="customEmail"></v-text-field>
                  <v-btn :loading="isSavingEmail" @click="saveCustomEmail" icon>
                    <v-icon
                    >mdi-content-save
                    </v-icon>
                  </v-btn>

                  <v-btn @click="() => editEmail(false)" icon>
                    <v-icon
                    >mdi-close-circle
                    </v-icon>
                  </v-btn>
                </div>

              </div>
            </v-card-text>
          </v-card>
          <v-card class="orderViewShippingAddressCard">
            <v-card-title>Shipping Address</v-card-title>
            <v-card-text>
              <p v-if="order.shippingAddress.company">
                {{ order.shippingAddress.company }}
              </p>
              <p>
                {{ order.shippingAddress.firstName }}
                {{ order.shippingAddress.lastName }}
              </p>
              <p>{{ order.shippingAddress.addressPrimary }}</p>
              <p>{{ order.shippingAddress.addressSecondary }}</p>
              <p>
                {{ order.shippingAddress.postCode }}
                {{ order.shippingAddress.city }}
              </p>
              <p>
                {{ order.shippingAddress.province }}
                {{ order.shippingAddress.countryCode }}
              </p>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn @click="showEditOrderAddressDialog = true" outlined
                >Edit
              </v-btn>
            </v-card-actions>
            <EditOrderAddressDialog
              :show="showEditOrderAddressDialog"
              :order="order"
              :on-close="closeEditOrderAddressDialog"
            />
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div v-else style="width: 100vh; height: 100vh">
      <StateDisplay :loading="loading" loading-message="Load Order" />
    </div>
    <ConfirmDialog
      :loading="isCanceling"
      icon="mdi-alert"
      message="Are you sure you want to Cancel the entire order?"
      :show="showCancelOrderDialog"
      :on-accept="cancelOrder"
      :on-cancel="() => (showCancelOrderDialog = false)"
    />
    <ManualFulfillDialog
      v-if="order.uid"
      :order-id="order.uid"
      :onClose="closeManualFulfillmentDialog"
      :show="showManualFulfillmentDialog"
    />
    <ReproduceOrderDialog
      v-if="order.uid && showReproduceDialog"
      :order-id="order.uid"
      :onClose="closeReproduceDialog"
      :show="showReproduceDialog"
    />
  </div>
</template>

<script>
import { db } from "../../fb";
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  setDoc,
} from "firebase/firestore";
import StateDisplay from "@/components/StateDisplay";
import OrderTasks from "@/components/AllOrders/components/OrderTasks";
import { copyToClipboard, getDateFromNow, openOrderInShopify } from "@/utils";
import EditOrderAddressDialog from "@/components/FulfillOrders/components/EditOrderAddressDialog";
import ManualFulfillDialog from "@/components/FulfillOrders/components/ManualFulfillDialog";
import { orderStatus, permissions, taskStatus } from "@/constants";
import ConfirmDialog from "@/components/ConfirmDialog";
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import ReproduceOrderDialog from "@/components/FulfillOrders/components/ReproduceOrderDialog";
import {EventBus, events} from "../../event-bus";

export default {
  name: "OrderView",
  components: {
    ReproduceOrderDialog,
    ConfirmDialog,
    ManualFulfillDialog,
    EditOrderAddressDialog,
    OrderTasks,
    StateDisplay,
  },
  data() {
    return {
      isEditEmail: "",
      customEmail: "",
      unsubscribe: null,
      order: null,
      loading: false,
      showEditOrderAddressDialog: false,
      showManualFulfillmentDialog: false,
      showCancelOrderDialog: false,
      showReproduceDialog: false,
      isCanceling: false,
      isSavingEmail: false,
    };
  },
  computed: {
    ...mapGetters(["userPermissions"]),
    showFulfillOptions() {
      return (
        this.userPermissions.includes(permissions.ADMIN) ||
        this.userPermissions.includes(permissions.FULFILL_ORDERS)
      );
    },
  },
  created() {
    this.loadOrder();
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  methods: {
    async loadOrder() {
      this.loading = true;
      const orderUid =
        this.$route.params.orderUid || this.$route.query.orderUid;
      const orderDoc = doc(db, `/orders/${orderUid}`);
      this.unsubscribe = onSnapshot(orderDoc, (snapshot) => {
        this.order = {
          localUpdateKey: Date.now(),
          ...snapshot.data(),
        };
      });
      this.loading = false;
    },
    editEmail(bool) {
      if (bool) {
        this.customEmail = this.order.customer.email
        this.isEditEmail = true
      } else {
        this.customEmail = ""
        this.isEditEmail = false
      }
    },
    async saveCustomEmail() {
      this.isSavingEmail = true
      const ordersCollectionRef = collection(db, "orders");
      const orderDoc = doc(ordersCollectionRef, this.order.uid);
      await setDoc(orderDoc, {
        customer: {
          email: this.customEmail
        }
      }, {merge: true})

      EventBus.$emit(events.SNACKBAR, {
        message: `Updated Email to ${this.customEmail}`,
        type: "success",
      });

      this.isSavingEmail = false
      this.isEditEmail = false
      this.order.customer.email = this.customEmail
      this.customEmail = ""
    },
    openReproduceDialog() {
      this.showReproduceDialog = true;
    },
    openManualFulfillmentDialog() {
      this.showManualFulfillmentDialog = true;
    },
    closeManualFulfillmentDialog() {
      this.showManualFulfillmentDialog = false;
    },
    closeReproduceDialog() {
      this.showReproduceDialog = false;
    },
    copyEmailToClipboard() {
      copyToClipboard(this.order.customer.email);
    },
    closeEditOrderAddressDialog() {
      this.showEditOrderAddressDialog = false;
    },
    getDateFromNow(date) {
      return getDateFromNow(date);
    },
    getDate(date) {
      return `${dayjs(date).format("DD.MM")} at ${dayjs(date).format(
        "hh:mm A"
      )}`;
    },
    openOrderInShopify() {
      openOrderInShopify({
        orderId: this.order.uid,
        countryCode: this.order.countryCode,
        shopId: this.order.shopId,
      });
    },
    async cancelOrder() {
      this.isCanceling = true;
      const ordersCollectionRef = collection(db, "orders");
      const orderDoc = doc(ordersCollectionRef, this.order.uid);
      const orderRes = await getDoc(orderDoc);
      const order = orderRes.data();

      for await (const orderTask of order.tasks) {
        const tasksCollectionRef = collection(db, "tasks");
        const taskDoc = doc(tasksCollectionRef, orderTask.taskUid);

        await setDoc(
          taskDoc,
          {
            status: taskStatus.CANCELED,
          },
          { merge: true }
        );
      }

      await setDoc(
        orderDoc,
        {
          status: orderStatus.CLOSED,
        },
        { merge: true }
      );
      this.showCancelOrderDialog = false;
      this.isCanceling = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.orderView {
}

.orderViewHeader {
  display: flex;
  align-items: center;

  &__orderName {
    font-size: 36px;
  }

  &__orderStatus {
    padding: 12px;
    background-color: #e0e0e0;
    border-radius: 5px;
    margin-left: 24px;
  }
}

.orderViewCustomerCard {
  margin-top: 24px;

  &__name {
    font-size: 18px;
  }

  &__email {
    font-size: 18px;
    font-weight: bold;
  }
}

.orderViewShippingAddressCard {
  margin-top: 24px;

  p {
    margin-bottom: 3px;
  }
}

.text-danger {
  color: #d70000;
}

.fulfillment {
  margin-top: 12px;

  &:first-of-type {
    margin-top: 0;
  }

  &__fulfillmentId {
    font-size: 18px;
    font-weight: bold;
  }

  &__creationDate {
    font-size: 16px;
    font-weight: lighter;
  }
}
</style>
